import { gql } from '@apollo/client';

export const PUBNUB_EQUITY_TRADE_OPPORTUNITY_UPDATED = gql`
  mutation PubNubEquityTradeOpportunityUpdated(
    $conversation_id: String!
    $equity_trade_opportunity: PubNubEquityTradeOpportunityModel!
  ) {
    PubNubEquityTradeOpportunityUpdated(
      conversation_id: $conversation_id
      equity_trade_opportunity: $equity_trade_opportunity
    ) @client
  }
`;
