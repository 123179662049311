import { gql } from '../../gql-tag';

export const GET_PHONELINE_BY_ID = gql(`
  query getPhoneline(
    $phonelineId: String!
    $withVoiceChannel: Boolean = false
    $withSMSChannel: Boolean = false
  ) {
    phoneline(phonelineId: $phonelineId) {
      ...PhoneLineWithChannelsFields
    }
  }
`);
