import {
  TranscriptAnalysisQl,
  TranscriptEventQl,
  TranscriptEventType,
  TranscriptQl,
  TranscriptScoreQl,
  TranscriptSectionQl,
} from '../../graphql';
import { GET_TRANSCRIPT } from '../../queries/gql/getTranscript.gql';
import type { ApolloContext } from '../../types';
import { safeRead } from '../../util';

export const convertTranscriptSection = (
  section: PubNubTranscriptAnalysisSectionModel,
): TranscriptSectionQl => {
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ __typename: "TranscriptSectionQL"; id:... Property 'createdOn' is missing in type...
  return {
    __typename: 'TranscriptSectionQL',
    id: section.id,
    name: section.name,
    timestamp: section.timestamp,
  };
};

export const convertTranscriptSections = (
  sections: Array<PubNubTranscriptAnalysisSectionModel>,
): Array<TranscriptSectionQl> => {
  return sections.map(section => convertTranscriptSection(section));
};

export const convertTranscriptScore = (
  score: PubNubTranscriptAnalysisScoreModel,
): TranscriptScoreQl => {
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ __typename: "TranscriptScoreQL"; id:... Property 'createdOn' is missing in type...
  return {
    __typename: 'TranscriptScoreQL',
    id: score.id,
    name: score.name,
    rating: score.rating,
    description: score.description,
  };
};

export const convertTranscriptScores = (
  scores: Array<PubNubTranscriptAnalysisScoreModel>,
): Array<TranscriptScoreQl> => {
  return scores.map(score => convertTranscriptScore(score));
};

export const convertTranscriptAnalysis = (
  analysis: PubNubTranscriptAnalysisModel,
): TranscriptAnalysisQl => {
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ __typename: "TranscriptAnalysisQL"; id:... Property 'createdOn' is missing in type...
  return {
    __typename: 'TranscriptAnalysisQL',
    id: analysis.id,
    timestamp: analysis.timestamp,
    callType: analysis.call_type,
    subject: analysis.subject,
    summary: analysis.summary,
    nextSteps: analysis.next_steps,
    coachingTip: analysis.coaching_tip,
    coachingSample: analysis.coaching_sample,
    sections: convertTranscriptSections(analysis.sections ?? []),
    scores: convertTranscriptScores(analysis.scores ?? []),
  };
};

export const convertTranscriptEvent = (
  event: PubNubTranscriptEventModel,
): TranscriptEventQl => {
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ __typename: "TranscriptEventQL"; id:... Property 'createdOn' is missing in type...
  return {
    __typename: 'TranscriptEventQL',
    id: event.id,
    type: event.type as TranscriptEventType,
    timestamp: event.timestamp,
    start: event.start,
    end: event.end,
    text: event.text,
    confidence: event.confidence,
    speaker: event.speaker,
    speakerConfidence: event.speaker_confidence,
  };
};

export const convertTranscriptEvents = (
  events?: Array<PubNubTranscriptEventModel | null> | null,
): Array<TranscriptEventQl> => {
  const nonNullEvents = events?.filter(event => event !== null) ?? [];
  return nonNullEvents.map(transcriptEvent =>
    convertTranscriptEvent(transcriptEvent as PubNubTranscriptEventModel),
  );
};

export const convertTranscript = (
  transcript: PubNubTranscriptModel,
): TranscriptQl => {
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ __typename: "TranscriptQL"; id:... Property 'createdOn' is missing in type...
  return {
    __typename: 'TranscriptQL',
    id: transcript.id,
    events: convertTranscriptEvents(transcript.events),
    analysis: transcript.analysis
      ? convertTranscriptAnalysis(transcript.analysis)
      : null,
  };
};

export const addTranscriptToCache = (
  { client }: ApolloContext,
  transcript: PubNubTranscriptModel,
) => {
  const newTranscript = convertTranscript(transcript);

  const data = safeRead(client, {
    query: GET_TRANSCRIPT,
    variables: { id: newTranscript.id },
  });

  client.writeQuery({
    query: GET_TRANSCRIPT,
    variables: { id: newTranscript.id },
    data: {
      transcript: {
        ...data?.transcript,
        ...newTranscript,
      },
    },
  });
};
