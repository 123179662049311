import type { ApolloContext } from '../../types';
import { getEquityTradeOpportunityQuery } from '../../features/equity-trade-opportunity';
import { EquityTradeOpportunityStage } from '../../__generated__/graphql';

export const PubNubEquityTradeOpportunityUpdated = (
  obj: any,
  args: {
    conversation_id: string;
    equity_trade_opportunity: { id: string; stage: string };
  },
  { client }: ApolloContext,
) => {
  client.writeQuery({
    query: getEquityTradeOpportunityQuery,
    variables: { conversationId: args.conversation_id },
    data: {
      equityTradeOpportunity: {
        __typename: 'EquityTradeOpportunityQL',
        id: args.equity_trade_opportunity.id,
        stage: args.equity_trade_opportunity
          .stage as EquityTradeOpportunityStage,
      },
    },
  });
};
