import * as React from 'react';

import type { WatchQueryFetchPolicy, ApolloError } from '@apollo/client';
import { Query } from '@apollo/client/react/components';

import type { ConversationBuckets } from '@numbox/util';
import { bucketConversations, bucketConversationsByWait } from '@numbox/util';

import { GET_FILTERED_CONVERSATIONS } from './gql/getFilteredConversations.gql';
import { GET_ASSIGNED_CONVERSATIONS } from './gql/getAssignedConversations.gql';
import { GET_UNASSIGNED_CONVERSATIONS } from './gql/getUnassignedConversations.gql';
import { GET_ALL_CONVERSATIONS } from './gql/getAllConversations.gql';

import { concatEdges, collapseEdges } from '../util';
import { COLLECTION_NAMES } from '../types';

type Props = {
  inboxId: string | null | undefined;
  assigneeId?: string | null | undefined;
  selectedState?: ConversationState | null | undefined;
  fetchPolicy?: WatchQueryFetchPolicy;
  children: (arg0: {
    conversations?: ConversationBuckets;
    error: void | ApolloError;
    loading: boolean;
    fetchMore: () => Promise<any> | null | undefined;
    hasMore: boolean;
    totalConversations: number;
    refetch: () => Promise<any> | null | undefined;
  }) => React.ReactElement | null;
  sort: InboxSort;
  isOverdue?: boolean | null;
  overdueConversationsPollingIntervalMsec?: number;
  isCurrentlyViewingConversation?: boolean;
};

export const getConversationQueryArgs = ({
  inboxId,
  state,
  assigneeId,
  sort = 'NEWEST',
  isOverdue,
}: {
  inboxId: string | null | undefined;
  state: ConversationState | null | undefined;
  assigneeId: string | null | undefined;
  sort: InboxSort | null | undefined;
  isOverdue?: boolean | null;
}) => {
  let inboxSort = 'last_updated_desc';
  if (sort === 'WAIT_TIME' && state !== 'ARCHIVED') {
    inboxSort = 'waiting_since_asc';
  }

  const keySuffix = `${state}-${isOverdue}-${inboxId}-${assigneeId}`;

  if (inboxId === COLLECTION_NAMES.ALL) {
    return {
      key: `all-${keySuffix}`,
      query: GET_ALL_CONVERSATIONS,
      variables: {
        state,
        sort: inboxSort,
        first: 16,
        isOverdue,
      },
    };
  }

  if (inboxId === COLLECTION_NAMES.UNASSIGNED) {
    return {
      key: `unassigned-${keySuffix}`,
      query: GET_UNASSIGNED_CONVERSATIONS,
      variables: {
        state,
        assigneeId: null,
        teamId: null,
        sort: inboxSort,
        isOverdue,
      },
    };
  }

  if (inboxId === COLLECTION_NAMES.ASSIGNED && assigneeId) {
    return {
      key: `assigned-${keySuffix}`,
      query: GET_ASSIGNED_CONVERSATIONS,
      variables: {
        state,
        assigneeId,
        sort: inboxSort,
        isOverdue,
      },
    };
  }

  if (inboxId === COLLECTION_NAMES.ADVISOR && assigneeId) {
    return {
      key: `advisor-${keySuffix}`,
      query: GET_ASSIGNED_CONVERSATIONS,
      variables: {
        state,
        assigneeId,
        sort: inboxSort,
        hasStatusItems: false,
        isOverdue,
      },
    };
  }

  return {
    key: `inbox-${keySuffix}`,
    query: GET_FILTERED_CONVERSATIONS,
    variables: {
      state,
      inboxId,
      sort: inboxSort,
      isOverdue,
    },
  };
};

export const GetFilteredConversations = ({
  children,
  inboxId,
  selectedState,
  assigneeId,
  fetchPolicy,
  sort,
  isOverdue,
  overdueConversationsPollingIntervalMsec,
  isCurrentlyViewingConversation,
}: Props) => {
  const state = isOverdue ? 'ACTIVE' : selectedState;

  if (!state && isOverdue === undefined) {
    return null;
  }

  const { query, variables, key } = getConversationQueryArgs({
    inboxId,
    state,
    assigneeId,
    sort,
    isOverdue,
  });

  // To avoid excess polling, only poll for overdue conversations and only if we're not currently
  // within a conversation.
  const pollInterval =
    isOverdue && !isCurrentlyViewingConversation
      ? overdueConversationsPollingIntervalMsec
      : 0;

  return (
    <Query<filteredConversations>
      key={key}
      query={query}
      variables={variables}
      fetchPolicy={fetchPolicy || 'cache-and-network'}
      notifyOnNetworkStatusChange
      pollInterval={pollInterval}
    >
      {({ data, error, loading, fetchMore: fetchNextPage, refetch }) => {
        if (error) {
          return children({
            error,
            loading,
            // @ts-expect-error ts-migrate(2322) FIXME: Type '() => void' is not assignable to type '() =>... Remove this comment to see the full error message
            fetchMore: () => {},
            hasMore: false,
            totalConversations: 0,
            refetch,
          });
        }

        if (!data || !data.conversations || !data.conversations.edges) {
          return children({
            loading,
            error,
            // @ts-expect-error ts-migrate(2322) FIXME: Type '() => void' is not assignable to type '() =>... Remove this comment to see the full error message
            fetchMore: () => {},
            hasMore: false,
            totalConversations: 0,
            refetch,
          });
        }
        const { conversations } = data;
        const { pageInfo } = conversations;

        const nodes = collapseEdges<
          filteredConversations$conversations,
          filteredConversations$conversations$edges$node
        >(conversations);

        const fetchMore = async () => {
          if (!pageInfo.hasNextPage) {
            return;
          }
          try {
            await fetchNextPage({
              variables: {
                after: pageInfo.endCursor,
              },
              // @ts-expect-error FIXME: apollo upgrade signature change
              updateQuery: (prev, { fetchMoreResult }) => ({
                conversations: concatEdges<
                  filteredConversations$conversations,
                  'conversations'
                >(prev, fetchMoreResult, 'conversations'),
              }),
            });
          } catch (e) {
            // likely component unmounted:
            // https://github.com/apollographql/apollo-client/issues/4114
          }
        };

        const relevantNodes = nodes.filter(c => c.state === state);

        let buckets;
        if (sort === 'WAIT_TIME' && state !== 'ARCHIVED') {
          buckets = bucketConversationsByWait(relevantNodes);
        } else {
          buckets = bucketConversations(relevantNodes);
        }
        const totalConversations = nodes.length;

        return children({
          conversations: buckets,
          fetchMore,
          loading,
          error,
          hasMore: pageInfo.hasNextPage,
          totalConversations,
          refetch,
        });
      }}
    </Query>
  );
};
