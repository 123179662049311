import { gql } from '@apollo/client';
import { TranscriptAnalysisFragment } from './transcriptAnalysis.gql';

export const PhoneCallFragment = gql`
  fragment PhoneCallFields on PhoneCallQL {
    id
    callDuration
    isActive
    endtime
    startTime
    conversationId
    transcript {
      id
      events {
        id
        type
      }
      subject
      summary
      analysis {
        ...TranscriptAnalysisFields
      }
    }
    hasReturnCallsRouting
    isBeingRecorded
    returnCallsRoutingOutcome
    returnCallsRoutedTo
    returnCallsRoutedUser {
      id
      displayName
    }
    participant {
      id
      address
      displayName
    }
    redirectedFrom {
      id
      address
      displayName
    }
    callRecording {
      id
      url
    }
  }
  ${TranscriptAnalysisFragment}
`;
