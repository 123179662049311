import { gql } from '../../gql-tag';

export const CREATE_CONVERSATION = gql(`
  mutation createConversation(
    $input: CreateConversationInput!
    $withLastAppointment: Boolean = false
    $withLastChannel: Boolean = false
    $withLastRepairOrder: Boolean = false
  ) {
    createConversation(input: $input) {
      conversation {
        ...ConversationItemFields
      }
    }
  }
`);
