import gql from 'graphql-tag';

export const COUNT_FILTERED_CONVERSATIONS = gql`
  query countFilteredConversations(
    $first: Int
    $after: String
    $inboxId: String
    $state: String
    $newerThan: ArrowField
    $sort: [ConversationModelSortEnum] = last_updated_desc
    $isOverdue: Boolean
  ) {
    conversations(
      first: $first
      after: $after
      sort: $sort
      queryFilter: {
        state: { equal: $state }
        inboxId: { equal: $inboxId }
        lastUpdated: { greaterThan: $newerThan }
        isOverdue: { equal: $isOverdue }
      }
    ) {
      totalCount
    }
  }
`;
