import { PubNubArchiveConversation } from '../resolvers/pubnub/PubNubArchiveConversation';
import { PubNubArchiveConversations } from '../resolvers/pubnub/PubNubArchiveConversations';
import { PubNubAssignConversation } from '../resolvers/pubnub/PubNubAssignConversation';
import { PubNubAwayModeChanged } from '../resolvers/pubnub/PubNubAwayModeChanged';
import { PubNubConversationDeleted } from '../resolvers/pubnub/PubNubConversationDeleted';
import { PubNubConversationLanguageReset } from '../resolvers/pubnub/PubNubConversationLanguageReset';
import { PubNubConversationRecovered } from '../resolvers/pubnub/PubNubConversationRecovered';
import { PubNubDegradedInboxExperienceUpdated } from '../resolvers/pubnub/PubNubDegradedInboxExperienceUpdated';
import { PubNubDialogFlowBotDataUpdated } from '../resolvers/pubnub/PubNubDialogFlowBotDataUpdated';
import { PubNubEquityTradeOpportunityUpdated } from '../resolvers/pubnub/PubNubEquityTradeOpportunityUpdated';
import { PubNubLeaveConversations } from '../resolvers/pubnub/PubNubLeaveConversations';
import { PubNubPaymentRequestCreated } from '../resolvers/pubnub/PubNubPaymentRequestCreated';
import { PubNubPaymentRequestUpdated } from '../resolvers/pubnub/PubNubPaymentRequestUpdated';
import { PubNubPlaceUpdated } from '../resolvers/pubnub/PubNubPlaceUpdated';
import { PubNubMessageReceived } from '../resolvers/pubnub/PubNubMessageReceived';
import { PubNubRestoreConversation } from '../resolvers/pubnub/PubNubRestoreConversation';
import { PubNubRestoreConversations } from '../resolvers/pubnub/PubNubRestoreConversations';
import { PubNubStripeIntegrationUpdated } from '../resolvers/pubnub/PubNubStripeIntegrationUpdated';
import { PubNubLabelCreated } from '../resolvers/pubnub/PubNubLabelCreated';
import { PubNubStatusItemCreated } from '../resolvers/pubnub/PubNubStatusItemCreated';
import { PubNubStatusItemUpdated } from '../resolvers/pubnub/PubNubStatusItemUpdated';
import { PubNubStatusItemDeleted } from '../resolvers/pubnub/PubNubStatusItemDeleted';
import { PubNubLabelUpdated } from '../resolvers/pubnub/PubNubLabelUpdated';
import { PubNubTeamCreated } from '../resolvers/pubnub/teams/PubNubTeamCreated';
import { PubNubTeamDeleted } from '../resolvers/pubnub/teams/PubNubTeamDeleted';
import { PubNubTeamUpdated } from '../resolvers/pubnub/teams/PubNubTeamUpdated';
import { PubNubVideoUploadLinkUpdated } from '../resolvers/pubnub/PubNubVideoUploadLinkUpdated';
import { PubNubOutboundConferenceCallStatusUpdated } from '../resolvers/pubnub/PubNubOutboundConferenceCallStatusUpdated';
import { PubNubOutboundProxyCallStatusUpdated } from '../resolvers/pubnub/PubNubOutboundProxyCallStatusUpdated';
import { PubNubInboundCallStatusUpdated } from '../resolvers/pubnub/PubNubInboundCallStatusUpdated';
import { PubNubAppointmentEnriched } from '../resolvers/pubnub/PubNubAppointmentEnriched';
import { PubNubConversationEngagementUpdated } from '../resolvers/pubnub/PubNubConversationEngagementUpdated';
import { PubNubTranscriptUpdated } from '../resolvers/pubnub/PubNubTranscriptUpdated';

export const clientResolvers = {
  Mutation: {
    PubNubAwayModeChanged,
    PubNubArchiveConversation,
    PubNubArchiveConversations,
    PubNubAssignConversation,
    PubNubConversationDeleted,
    PubNubConversationLanguageReset,
    PubNubConversationRecovered,
    PubNubDegradedInboxExperienceUpdated,
    PubNubDialogFlowBotDataUpdated,
    PubNubEquityTradeOpportunityUpdated,
    PubNubLeaveConversations,
    PubNubPaymentRequestCreated,
    PubNubPaymentRequestUpdated,
    PubNubPlaceUpdated,
    PubNubMessageReceived,
    PubNubRestoreConversation,
    PubNubRestoreConversations,
    PubNubStripeIntegrationUpdated,
    PubNubLabelCreated,
    PubNubLabelUpdated,
    PubNubStatusItemCreated,
    PubNubStatusItemUpdated,
    PubNubStatusItemDeleted,
    PubNubTeamCreated,
    PubNubTeamDeleted,
    PubNubTeamUpdated,
    PubNubTranscriptUpdated,
    PubNubVideoUploadLinkUpdated,
    PubNubOutboundConferenceCallStatusUpdated,
    PubNubOutboundProxyCallStatusUpdated,
    PubNubInboundCallStatusUpdated,
    PubNubAppointmentEnriched,
    PubNubConversationEngagementUpdated,
  },
};
