import { gql } from '../../gql-tag';

export const ADD_AUTOMATION_EXIT_TRIGGER = gql(`
  mutation addAutomationExitTrigger($input: AddAutomationExitTriggerInput!) {
    addAutomationExitTrigger(input: $input) {
      automation {
        ...AutomationFields
      }
    }
  }
`);
