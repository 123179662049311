import { gql } from '../../gql-tag';

export const ADD_CUSTOM_LINK = gql(`
  mutation AddCustomLink($input: AddCustomLinkInput) {
    addCustomLink(input: $input) {
      placeInfo {
        id
        linkGroups {
          groupLabel
          links {
            name
            tag
            url
          }
        }
      }
    }
  }
`);
