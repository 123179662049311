import type { ApolloContext } from '../../types';
import { addTranscriptToCache } from './AddTranscriptToCache';

export const PubNubTranscriptUpdated = async (
  obj: any,
  args: { transcript: PubNubTranscriptModel },
  client: ApolloContext,
) => {
  addTranscriptToCache(client, args.transcript);
};
