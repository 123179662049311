import { useFragment, useMutation } from '@apollo/client';
import { adjustDateByIntervals, normalizedDate } from '@numbox/util';
import { gql } from '../gql-tag';
import { LOAD_CHAT_HISTORY } from '../queries/gql/loadChatHistory.gql';
import { getChatHistoryQueryVariables } from '../queries/LoadChatHistory';
import {
  LoadChatHistoryQuery,
  LoadChatHistoryQueryVariables,
} from '../graphql';

export const engagementWithSummaryFieldsFragment = gql(`
  fragment EngagementWithSummaryFields on EngagementQL {
    id
    summaryId
    summary
    mySummaryRating {
      value
      reason
      details
    }
  }
`);

const conversationLastEngagementFragment = gql(`
  fragment ConversationLastEngagement on ConversationQL {
    lastEngagement {
      ...EngagementWithSummaryFields
    }
  }
`);

/**
 * Returns the last engagement for a conversation.
 *
 * NOTE: requires that the conversation has been loaded upstream
 * with the `lastEngagment` field.
 */
export const useConversationLastEngagement = (conversationId: string) => {
  const { data } = useFragment({
    fragment: conversationLastEngagementFragment,
    fragmentName: 'ConversationLastEngagement',
    from: { __typename: 'ConversationQL', id: conversationId },
  });

  return data?.lastEngagement ?? null;
};

export const rateEngagementSummaryMutation = gql(`
  mutation rateEngagementSummary($input: RateEngagementSummaryInput!) {
    rateEngagementSummary(input: $input) {
      ...EngagementWithSummaryFields
      conversation {
        id
        hasRecentHeatCase
      }
    }
  }
`);

function shouldClearHeatCase(date: string) {
  const threeDaysAgo = adjustDateByIntervals(-3, 'day', new Date()).getTime();
  const dateObj = normalizedDate(date);
  console.log('Three days ago', threeDaysAgo, 'Date', dateObj.getTime());
  return dateObj.getTime() > threeDaysAgo;
}

export const useRateEngagementSummary = () => {
  return useMutation(rateEngagementSummaryMutation, {
    optimisticResponse: ({ input }) => {
      return {
        rateEngagementSummary: {
          id: input.engagementId,
          summaryId: input.summaryId,
          mySummaryRating: {
            value: input.rating.value,
            reason: input.rating.reason,
            details: input.rating.details,
          },
        },
      };
    },
    update: (cache, { data }) => {
      const conversationId = data?.rateEngagementSummary?.conversation?.id;
      if (
        conversationId &&
        data?.rateEngagementSummary?.mySummaryRating?.reason ===
          'not_a_heat_case'
      ) {
        const chatHistoryQueryVariables = getChatHistoryQueryVariables({
          conversationId,
          withScheduledMessages: true,
        });

        // Rating a conversation as "not a heat case" will invalidate the sentiments
        // for all messages on the backend that might have been heat cases in last 3 days,
        // this replicates that behaviour in local cache for better user-feedback.
        cache.updateQuery<LoadChatHistoryQuery, LoadChatHistoryQueryVariables>(
          {
            query: LOAD_CHAT_HISTORY,
            variables: chatHistoryQueryVariables,
          },
          chatHistory => {
            if (!chatHistory?.messages?.edges) {
              return chatHistory;
            }
            return {
              ...chatHistory,
              messages: {
                ...chatHistory.messages,
                edges: chatHistory.messages.edges.map(edge => {
                  if (edge?.node && shouldClearHeatCase(edge.node.timestamp)) {
                    return {
                      ...edge,
                      node: {
                        ...edge.node,
                        isHeatCase: false,
                      },
                    };
                  }
                  return edge;
                }),
              },
            };
          },
        );
      }
    },
  });
};
