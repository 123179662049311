import {
  TypedDocumentNode,
  useFragment,
  useMutation,
  useQuery,
} from '@apollo/client';
import { useCallback } from 'react';
import {
  ConversationState,
  GetConversationByIdQuery,
  GetConversationByIdQueryVariables,
  SnoozeConversationDocument,
} from '../graphql';
import { GET_CONVERSATION_BY_ID } from '../queries/gql/getConversationById.gql';
import { gql } from '../gql-tag';
import { updateActiveInbox } from '../util';
import { GraphQLClient } from '../client/GraphQLClient';
import { SNOOZE_CONVERSATION } from '../mutations/gql/snoozeConversation.gql';
import { COUNT_ASSIGNED_CONVERSATIONS } from '../queries/gql/countAssignedConversations';
import { COUNT_UNASSIGNED_CONVERSATIONS } from '../queries/gql/countUnassignedConversations';
import { COUNT_ALL_CONVERSATIONS } from '../queries/gql/countAllConversations';
import { COUNT_FILTERED_CONVERSATIONS } from '../queries/gql/countFilteredConversations';
import { getConversationQueryArgs } from '../queries/GetFilteredConversations';
import { COLLECTION_NAMES } from '../types';
import { GET_ACTIVE_INBOX } from '../queries/gql/getActiveInbox.gql';

const getCountConversationsQuery = (inboxId?: string | null) => {
  switch (inboxId) {
    case COLLECTION_NAMES.ASSIGNED:
    case COLLECTION_NAMES.ADVISOR:
      return COUNT_ASSIGNED_CONVERSATIONS;
    case COLLECTION_NAMES.UNASSIGNED:
      return COUNT_UNASSIGNED_CONVERSATIONS;
    case COLLECTION_NAMES.ALL:
      return COUNT_ALL_CONVERSATIONS;
    default:
      return COUNT_FILTERED_CONVERSATIONS;
  }
};

export const useGetConversationById = (conversationId: string) => {
  const graphQlQuery = GET_CONVERSATION_BY_ID as TypedDocumentNode<
    GetConversationByIdQuery,
    GetConversationByIdQueryVariables
  >;

  return useQuery(graphQlQuery, {
    variables: {
      conversationId,
      withLastAppointment: true,
      withLastChannel: false,
      withLastRepairOrder: true,
    },
    fetchPolicy: 'cache-and-network',
    context: { batchable: true },
    notifyOnNetworkStatusChange: true,
  });
};

const moveConversationMutation = gql(`
  mutation MoveConversation(
    $conversationId: String!,
    $newState: ConversationState!,
  ) {
    updateConversations(input: { ids: [$conversationId], state: $newState }) {
      conversations {
        ...ConversationItemStub
      }
    }
  }
`);

const useConversationInboxId = (conversationId: string) => {
  // FIXME: we shouldn't need the inbox ID for most of those updates
  // but the item stub fragment + existing optimistic response mutation
  // requires us to, due to its usage in the updateActiveInbox function.
  const { data } = useFragment({
    fragment: gql(`
      fragment ConversationInboxFragment on ConversationQL {
        inboxId
      }
    `),
    from: {
      __typename: 'ConversationQL',
      id: conversationId,
    },
  });

  return data.inboxId;
};

const useIsFilteringToOverdue = () => {
  // NOTE: The activeInbox query is a client-only query, do not anticipate any network I/O.
  const { data: activeInboxData } = useQuery(GET_ACTIVE_INBOX);
  return !!activeInboxData?.activeInbox?.isOverdue;
};

export const useMoveConversation = (conversationId: string) => {
  const inboxId = useConversationInboxId(conversationId);
  const isFilteringToOverdue = useIsFilteringToOverdue();

  let refetchQueries: Array<string | TypedDocumentNode> = [
    'myInboxes',
    'loadChatHistory',
  ];

  // NOTE: Only one query will get refetched at any time, depending on what inbox is selected.
  // This is because only one of these queries is "active" at a time.
  // Ref: https://www.apollographql.com/docs/react/data/mutations#refetching-queries
  if (isFilteringToOverdue) {
    refetchQueries = [
      ...refetchQueries,
      COUNT_ALL_CONVERSATIONS,
      COUNT_ASSIGNED_CONVERSATIONS,
      COUNT_UNASSIGNED_CONVERSATIONS,
      COUNT_FILTERED_CONVERSATIONS,
    ];
  }

  const [moveConversation] = useMutation(moveConversationMutation, {
    optimisticResponse: inputs => {
      return {
        updateConversations: {
          conversations: [
            {
              __typename: 'ConversationQL' as const,
              id: inputs.conversationId,
              state: inputs.newState,
              lastUpdated: new Date().toISOString(),
              waitingSince: null,
              // should always be loaded when we emit this op
              inboxId: inboxId ?? 'UNKNOWN',
            },
          ],
        },
      };
    },
    refetchQueries,
    update: (cache, response) => {
      if (response.data?.updateConversations?.conversations) {
        updateActiveInbox(
          GraphQLClient,
          // @ts-expect-error FIXME: update active inbox assumes
          // legacy types
          response.data.updateConversations.conversations,
        );
      }
    },
  });

  return useCallback(
    (newState: ConversationState) =>
      moveConversation({ variables: { conversationId, newState } }),
    [moveConversation, conversationId],
  );
};

export const useSnoozeConversation = (conversationId: string) => {
  const inboxId = useConversationInboxId(conversationId);
  const isFilteringToOverdue = useIsFilteringToOverdue();

  let refetchQueries: Array<string | TypedDocumentNode> = [];
  // NOTE: Only one query will get refetched at any time, depending on what inbox is selected.
  // This is because only one of these queries is "active" at a time.
  // Ref: https://www.apollographql.com/docs/react/data/mutations#refetching-queries
  if (isFilteringToOverdue) {
    refetchQueries = [
      ...refetchQueries,
      COUNT_ALL_CONVERSATIONS,
      COUNT_ASSIGNED_CONVERSATIONS,
      COUNT_UNASSIGNED_CONVERSATIONS,
      COUNT_FILTERED_CONVERSATIONS,
    ];
  }

  const [snoozeConversation] = useMutation(
    SNOOZE_CONVERSATION as typeof SnoozeConversationDocument,
    {
      optimisticResponse: ({ input }) => {
        return {
          snoozeConversation: {
            conversation: {
              id: input.id,
              state: ConversationState.Snoozed,
              // should always be loaded when we emit this op
              inboxId: inboxId ?? 'UNKNOWN',
            },
          },
        };
      },
      refetchQueries,
    },
  );

  return useCallback(
    (snoozeUntil: Date) =>
      snoozeConversation({
        variables: { input: { id: conversationId, snoozeUntil } },
      }),
    [snoozeConversation, conversationId],
  );
};

type UseCountConversationProps = {
  inboxId: string | null | undefined;
  state: ConversationState | null | undefined;
  assigneeId: string | null | undefined;
  sort: InboxSort | null | undefined;
  isOverdue?: boolean | null;
  skip?: boolean;
  pollInterval?: number;
};

export const useCountConversations = ({
  skip,
  pollInterval,
  ...props
}: UseCountConversationProps) => {
  const { variables } = getConversationQueryArgs(props);

  const query = getCountConversationsQuery(props.inboxId);

  const { data, loading, error } = useQuery(query, {
    variables,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip,
    pollInterval,
  });

  return {
    totalCount: data?.conversations?.totalCount,
    loading,
    error,
  };
};
