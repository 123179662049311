import { useMutation, useQuery } from '@apollo/client';
import { gql } from '../gql-tag';

export const updatablePlaceInfoFieldsFragment = gql(`
  fragment UpdatablePlaceInfoFields on PlaceInfoQL {
    name
    streetAddress1
    streetAddress2
    city
    state
    zipCode
    phonenumber
    websiteUrl
    email
    place {
      id
      name
      externalName
      brandTheme {
        id
        logoUrl
      }
    }
  }
`);

export const placeInfoFieldsFragment = gql(`
  fragment PlaceInfoFields on PlaceInfoQL {
    id
    name
    address
    streetAddress1
    streetAddress2
    city
    state
    zipCode
    phonenumber
    email
    websiteUrl
    logoUrl
    linkGroups {
      groupLabel
      links {
        name
        tag
        url
      }
    }
  }
`);

export const getPlaceInfoQuery = gql(`
  query getPlaceInfo($placeId: String!) {
    placeInfo(placeId: $placeId) {
      ...PlaceInfoFields
      ...UpdatablePlaceInfoFields
    }
  }
`);

export const updatePlaceInfoMutation = gql(`
  mutation updatePlaceInfo($input: UpdatePlaceInfoInput!) {
    updatePlaceInfo(input: $input) {
      placeInfo {
        id
        ...UpdatablePlaceInfoFields
      }
    }
  }
`);

export const useUpdatePlaceInfo = (placeId: string) => {
  // we can reasonably assume that the placeInfo will loaded
  // by the time a mutation runs
  const { data } = useQuery(getPlaceInfoQuery, {
    variables: { placeId },
    fetchPolicy: 'cache-only',
  });

  const placeInfo = data?.placeInfo;

  return useMutation(updatePlaceInfoMutation, {
    optimisticResponse: ({ input }) => {
      const updatedParams = {
        name: input.name,
        streetAddress1: input.streetAddress1,
        streetAddress2: input.streetAddress2,
        city: input.city,
        state: input.state,
        zipCode: input.zipCode,
        phonenumber: input.phonenumber,
        websiteUrl: input.websiteUrl,
        email: input.email,
      };

      return {
        updatePlaceInfo: {
          placeInfo: {
            id: `tmp-for-place:${input.placeId}`,
            __typename: 'PlaceInfoQL',
            ...placeInfo,
            ...updatedParams,
            place: {
              ...placeInfo?.place,
              __typename: 'PlaceQL',
              id: input.placeId,
              name: placeInfo?.place?.name ?? 'unknown',
              externalName:
                input.name ?? placeInfo?.place?.externalName ?? 'unknown',
            },
          },
        },
      } as const;
    },
  });
};
