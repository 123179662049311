import { useMutation } from '@apollo/client';
import { gql } from '../gql-tag';
import { EquityTradeOpportunityStage } from '../graphql';

export const equityTradeOpportunityWithStageFragment = gql(`
  fragment EquityTradeOpportunityWithStage on EquityTradeOpportunityQL {
    id
    stage
  }
`);

export const getEquityTradeOpportunityQuery = gql(`
  query getEquityTradeOpportunity($conversationId: String!) {
    equityTradeOpportunity(conversationId: $conversationId) {
      ...EquityTradeOpportunityWithStage
    }
  }
`);

export const updateEquityTradeOpportunityStageMutation = gql(`
  mutation updateEquityTradeOpportunityStage(
    $input: UpdateEquityTradeOpportunityStageInput!
  ) {
    updateEquityTradeOpportunityStage(input: $input) {
      equityTradeOpportunity {
        ...EquityTradeOpportunityWithStage
      }
    }
  }
`);

export const useUpdateEquityTradeOpportunityStage = () => {
  return useMutation(updateEquityTradeOpportunityStageMutation, {
    optimisticResponse: vars => {
      return {
        updateEquityTradeOpportunityStage: {
          equityTradeOpportunity: {
            __typename: 'EquityTradeOpportunityQL' as const,
            id: vars.input.equityTradeOpportunityId,
            // FIXME: input type should be EquityTradeOpportunityStage, not string
            stage: vars.input.stage as EquityTradeOpportunityStage,
          },
        },
      };
    },
  });
};
