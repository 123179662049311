import { gql } from '../../gql-tag';

export const GET_ACCOUNTS_QUERY = gql(`
  query accounts(
    $first: Int
    $after: String
    $filter: AccountModelFilter
    $sort: [AccountModelSortEnum]
  ) {
    accounts(first: $first, after: $after, queryFilter: $filter, sort: $sort) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          name
          status
          twilioSubaccountSid
          billingIdentity {
            id
            billingOrderId
            subscriptions {
              id
              quantity
              available
              sku {
                id
                isActive
                skuType
                name
              }
            }
          }
        }
      }
    }
  }
`);
