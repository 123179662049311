import { gql } from '../../gql-tag';

export const GET_ACCOUNT_OWNERS = gql(`
  query getAccountOwners {
    currentAccount {
      id
      owners {
        id
        displayName
        fullName
        email
        isAway
      }
    }
  }
`);
