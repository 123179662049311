import {
  ApolloClient,
  ApolloLink,
  Observable,
  Operation,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import get from 'lodash/get';

import type { Store } from 'redux';

import { testIsAuth0Token } from '@numbox/util';

import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from './constants';
import { REFRESH_TOKEN } from '../mutations/gql/refreshToken.gql';

// Copied from apollo-link-http_v1.2.x.js since it's not an exported type
type NextLink = (operation: Operation) => any;

type ApolloRefreshPayload = {
  refreshToken?: string | null | undefined;
  accessToken?: string | null | undefined;
  authError?: string | null | undefined;
  featureFlags?: Array<string>;
};

type RefreshTokenLinkConfig = {
  client: ApolloClient<{}>;
  refreshTokenPath: string;
  store: Store<any, any>;
  storage?: any;
  attemptSilentAuth0Login?: ({
    onSuccess,
    onFailure,
    cacheMode,
    storeToken,
  }: {
    onSuccess?:
      | ((
          accessToken: string,
          refreshToken: string | undefined,
          userData: any,
        ) => void)
      | undefined;
    onFailure?: (() => void) | undefined;
    cacheMode?: 'on' | 'off' | 'cache-only';
    storeToken?: boolean;
  }) => Promise<void>;
  onAuth0LoginFailure?: () => void;
};

export class RefreshTokenLink extends ApolloLink {
  // @ts-expect-error ts-migrate(2564) FIXME: Property 'config' has no initializer and is not definitely assigned in the constructor.
  config: RefreshTokenLinkConfig;

  request = (operation: Operation, forward?: NextLink) => {
    return this.link.request(operation, forward);
  };

  injectConfig = (config: RefreshTokenLinkConfig): void => {
    this.config = config;
  };

  getAuth0Token = async (): Promise<any | null> => {
    const { attemptSilentAuth0Login, onAuth0LoginFailure } = this.config;

    let newCredentials = null;
    if (attemptSilentAuth0Login) {
      await attemptSilentAuth0Login({
        cacheMode: 'off',
        storeToken: false,
        onSuccess: (
          accessToken: string,
          refreshToken: string | undefined,
          userData: any,
        ) => {
          const flags = (userData?.flags ?? {}) as { [key: string]: boolean };
          newCredentials = {
            accessToken,
            refreshToken,
            flags: Object.keys(flags).reduce((acc, key) => {
              if (!key.startsWith('$') && flags[key]) {
                return [...acc, key];
              }
              return acc;
            }, [] as Array<string>),
          };
        },
        onFailure: onAuth0LoginFailure,
      });
    }
    return newCredentials;
  };

  getRefreshToken = async (): Promise<string | null> => {
    const { refreshTokenPath, storage, store } = this.config;
    const refreshToken = get(store.getState(), refreshTokenPath);

    if (refreshToken) {
      return refreshToken;
    }
    if (storage) {
      return storage.getItem(REFRESH_TOKEN_KEY);
    }
    return null;
  };

  syncWithRedux = (refreshPayload: ApolloRefreshPayload): void => {
    const { store, storage } = this.config;
    const { refreshToken, accessToken } = refreshPayload;
    if (storage) {
      if (refreshToken) {
        storage.setItem(REFRESH_TOKEN_KEY, refreshToken);
      } else {
        storage.removeItem(REFRESH_TOKEN_KEY);
      }
      if (accessToken) {
        storage.setItem(ACCESS_TOKEN_KEY, accessToken);
      } else {
        storage.removeItem(ACCESS_TOKEN_KEY);
      }
    }
    store.dispatch({
      type: 'APOLLO_TOKEN_REFRESH',
      payload: refreshPayload,
    });

    if (refreshPayload.featureFlags) {
      store.dispatch({
        type: 'FEATUREFLAG_REFRESH',
        payload: {
          flags: refreshPayload.featureFlags.reduce((acc, flag) => {
            acc[flag] = true;
            return acc;
          }, {} as Record<string, boolean>),
        },
      });
    }
  };

  link = onError(
    /* eslint-disable consistent-return */
    ({ graphQLErrors, operation, response, forward, networkError }) => {
      if (graphQLErrors) {
        const authError = graphQLErrors.find(e =>
          [401].includes(e.extensions?.code ?? 0),
        );

        if (authError && operation.operationName !== 'refreshToken') {
          return new Observable(observer => {
            this.getRefreshToken()
              .then(token => {
                const { client } = this.config;
                if (token) {
                  const isAuth0Token = testIsAuth0Token(token);
                  return Promise.all([
                    isAuth0Token,
                    isAuth0Token
                      ? this.getAuth0Token()
                      : client.mutate<refreshToken>({
                          mutation: REFRESH_TOKEN,
                          fetchPolicy: 'no-cache',
                          variables: { refreshToken: token },
                        }),
                  ]);
                }
                return Promise.all([false, null]);
              })
              .then(([isAuth0Token, newCredentials]) => {
                if (isAuth0Token && newCredentials?.accessToken) {
                  return newCredentials;
                }

                if (newCredentials && newCredentials.errors) {
                  throw Error(
                    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
                    newCredentials.errors.map(e => e.message).join('\n'),
                  );
                }

                if (
                  !newCredentials ||
                  !newCredentials.data ||
                  !newCredentials.data.login
                ) {
                  throw Error('error trying to refresh token');
                }

                return newCredentials.data.login;
              })
              .then(authData => {
                const { accessToken } = authData;
                this.syncWithRedux(authData);
                const oldHeaders = operation.getContext().headers;
                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    authorization: accessToken ? `Bearer ${accessToken}` : '',
                  },
                });

                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                };

                // Retry last failed request
                forward(operation).subscribe(subscriber);
              })
              .catch(e => {
                console.log('failed to reauthorize', e);
                this.syncWithRedux({
                  authError: 'Access token expired. Please login again',
                });

                observer.error(e);
              });
          });
        }
      } else if (operation.operationName === 'refreshToken' && response) {
        // we don't want to return the auth errors up the chain
        // since we handled them by refreshing the token already
        // this will help avoid unintended unhandled rejected promises
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'readonly Gr... Remove this comment to see the full error message
        response.errors = null;
      }
      if (networkError) {
        console.log(operation.operationName, '[Network error]', networkError);
      }
    },
  );
}
