import { gql } from '../../gql-tag';

export const UPDATE_LINK = gql(`
  mutation UpdateLink($input: UpdateLinkInput) {
    updateLink(input: $input) {
      placeInfo {
        id
        linkGroups {
          groupLabel
          links {
            name
            tag
            url
          }
        }
      }
    }
  }
`);
