import { useMutation } from '@apollo/client';
import { gql } from '../gql-tag';

const forceActiveIntegrationSyncMutation = gql(`
  mutation forceActiveIntegrationSync(
    $input: ForceActiveIntegrationSyncInput!
  ) {
    forceActiveIntegrationSync(input: $input) {
      activeIntegration {
        id
      }
    }
  }
`);

export const useForceActiveIntegrationSync = () => {
  return useMutation(forceActiveIntegrationSyncMutation);
};
