import { gql } from '../../gql-tag';

export const UPDATE_PHONE_LINE = gql(`
  mutation UpdatePhoneLine(
    $input: UpdatePhoneLineInput!
  ) {
    updatePhoneLine(input: $input) {
      phoneline {
        ...PhonelineCategorySettingsFields
      }
    }
  }
`);
