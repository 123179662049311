import { useMutation, useQuery } from '@apollo/client';
import { gql } from '../gql-tag';

export const GET_APPOINTMENT_AGENT_SETTINGS_LABELS = gql(`
  query ApppointmentAgentSettings($id: String!) {
    appointmentAgentSettings(id: $id) {
      labels {
        id
        category
        label {
          ...LabelFields
        }
      }
    }
  }
`);

export const useAppointmentAgentSettings = (id: string) => {
  return useQuery(GET_APPOINTMENT_AGENT_SETTINGS_LABELS, {
    variables: { id },
  });
};

const createAppointmentAgentSettingLabelMutation = gql(`
  mutation CreateAppointmentAgentSettingLabel($input: CreateAppointmentAgentSettingLabelInput!) {
    createAppointmentAgentSettingLabel(input: $input) {
      id
  }}
`);

export const useCreateAppointmentAgentSettingLabel = () => {
  return useMutation(createAppointmentAgentSettingLabelMutation, {
    optimisticResponse: ({ input }) => {
      return {
        createAppointmentAgentSettingLabel: {
          ...input,
        },
      };
    },
  });
};

const updateAppointmentAgentSettingLabelMutation = gql(`
  mutation UpdateAppointmentAgentSettingLabel($input: UpdateAppointmentAgentSettingLabelInput!) {
    updateAppointmentAgentSettingLabel(input: $input) {
      id
  }}
`);

export const useUpdateAppointmentAgentSettingLabel = () => {
  return useMutation(updateAppointmentAgentSettingLabelMutation, {
    optimisticResponse: ({ input }) => {
      return {
        updateAppointmentAgentSettingLabel: {
          ...input,
        },
      };
    },
  });
};

const deleteAppointmentAgentSettingLabelMutation = gql(`
  mutation DeleteAppointmentAgentSettingLabel($input: DeleteAppointmentAgentSettingLabelInput!) {
    deleteAppointmentAgentSettingLabel(input: $input) {
      id
  }}
`);

export const useDeleteAppointmentAgentSettingLabel = () => {
  return useMutation(deleteAppointmentAgentSettingLabelMutation, {
    optimisticResponse: ({ input }) => {
      return {
        deleteAppointmentAgentSettingLabel: {
          ...input,
        },
      };
    },
  });
};

export const duplicateAppointmentAgentSettingsMutation = gql(`
  mutation duplicateAppointmentAgentSettings(
    $input: DuplicateAppointmentAgentSettingsInput!
  ) {
    duplicateAppointmentAgentSettings(input: $input) {
      appointmentAgentSettings {
        id
      }
    }
  }
`);

export const useDuplicateAppointmentAgentSettings = () => {
  return useMutation(duplicateAppointmentAgentSettingsMutation);
};

export const GET_APPOINTMENT_AGENT_NOTE_RATING = gql(`
  query AppointmentAgentNoteRating($dialogFlowBotDataId: String!, $userId: String!) {
    appointmentAgentNoteRating(dialogFlowBotDataId: $dialogFlowBotDataId, userId: $userId) {
      value
      reason
      details
    }
  }
`);

export const useAppointmentAgentNoteRating = (
  dialogFlowBotDataId: string,
  userId: string,
) => {
  return useQuery(GET_APPOINTMENT_AGENT_NOTE_RATING, {
    variables: { dialogFlowBotDataId, userId },
  });
};

export const RATE_APPOINTMENT_AGENT_NOTE_MUTATION = gql(`
  mutation rateAppointmentAgentNote($input: RateAppointmentAgentNoteInput!) {
    rateAppointmentAgentNote(input: $input) {
      value
      reason
      details
    }
  }
`);

export const useRateAppointmentAgentNote = () => {
  return useMutation(RATE_APPOINTMENT_AGENT_NOTE_MUTATION, {
    optimisticResponse: ({ input }) => {
      return {
        rateAppointmentAgentNote: {
          value: input.rating.value,
          reason: input.rating.reason,
          details: input.rating.details,
        },
      };
    },
  });
};
