import { useQuery } from '@apollo/client';
import { gql } from '../gql-tag';

export const baseCustomerFieldsFragment = gql(`
  fragment BaseCustomerFields on CustomerQL {
    id
    firstName
    lastName
    displayName
    addresses {
      id
      address
      mediumType
      isPrimary
      isActive
      deletedAt
      sourceEntityId
      sourceEntityType
      addressConsentStatuses {
        consentType
        consentCategory
      }
    }
    lastAppointment {
      ...AppointmentFields
    }
    lastRepairOrder {
      ...RepairOrderFields
    }
  }
`);
export const customerFieldsWithDuplicatesFragment = gql(`
  fragment CustomerFieldsWithDuplicates on CustomerQL {
    ...BaseCustomerFields
    duplicates {
        ...BaseCustomerFields
      }

  }
`);

export const customerFieldsWithRoAndAppointmentDuplicatesFragment = gql(`
  fragment CustomerFieldsWithRoAndAppointmentDuplicates on CustomerQL {
    ...BaseCustomerFields
    duplicates {
        lastAppointment {
          ...AppointmentFields
        }
        lastRepairOrder {
          ...RepairOrderFields
        }
      }
  }
`);

export const getCustomerByIdQuery = gql(`
  query getCustomerById($customerId: String!) {
    customer(customerId: $customerId) {
      ...CustomerFieldsWithDuplicates
    }
  }
  `);
export const getCustomerWithRoAndAppointmentDuplicatesByIdQuery = gql(`
    query getCustomerWithRoAndAppointmentDuplicatesById($customerId: String!) {
      customer(customerId: $customerId) {
        ...CustomerFieldsWithRoAndAppointmentDuplicates
      }
    }
    `);

export const useGetCustomerById = (customerId: string | null | undefined) => {
  return useQuery(getCustomerByIdQuery, {
    variables: { customerId: customerId ?? '' },
    fetchPolicy: 'cache-first',
    skip: customerId == null,
  });
};
export const useGetCustomerWithROAndAppointmentDuplicateById = (
  customerId: string | null | undefined,
) => {
  return useQuery(getCustomerWithRoAndAppointmentDuplicatesByIdQuery, {
    variables: { customerId: customerId ?? '' },
    fetchPolicy: 'cache-first',
    skip: customerId == null,
  });
};
