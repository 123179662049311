import { gql } from '../../gql-tag';

export const DELETE_CUSTOM_LINK = gql(`
  mutation DeleteCustomLink($input: DeleteCustomLinkInput) {
    deleteCustomLink(input: $input) {
      placeInfo {
        id
        linkGroups {
          groupLabel
          links {
            name
            tag
            url
          }
        }
      }
    }
  }
`);
