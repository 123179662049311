import { gql } from '@apollo/client';

export const UPDATE_APPOINTMENT_AGENT_SETTINGS = gql`
  mutation updateAppointmentAgentSettings(
    $input: UpdateAppointmentAgentSettingsInput!
  ) {
    updateAppointmentAgentSettings(input: $input) {
      appointmentAgentSettings {
        __typename
        id
        name
        settings
        agentProfile
        optionalPrompts
        textingCanWeTextSpeech
        textingOnYesSpeechCanWeText
        textingOnNoSpeechCanWeText
        textingMessageToSend
        textingHangupRecovery
        renderedTextingMessageToSend
        renderedTextingHangupRecovery
        activeIntegrationId
        minimumLeadTimeInMinutes
        maximumBookingHorizonInDays
        enableHangupRecovery
      }
    }
  }
`;
