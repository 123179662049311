import { gql } from '../../gql-tag';

export const ADD_ANSWERING_SCRIPT_TO_POLICY = gql(`
  mutation AddAnsweringScriptToPolicy(
    $input: AddAnsweringScriptToPolicyInput!
  ) {
    addAnsweringScriptToPolicy(input: $input) {
      phoneLinePolicy {
        id
        answeringScripts {
          id
          scriptType
          order
        }
      }
    }
  }
`);
