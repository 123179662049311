import gql from 'graphql-tag';

export const COUNT_ALL_CONVERSATIONS = gql`
  query countAllConversations(
    $first: Int
    $after: String
    $state: String
    $newerThan: ArrowField
    $sort: [ConversationModelSortEnum]
    $isOverdue: Boolean
  ) {
    conversations(
      first: $first
      after: $after
      sort: $sort
      queryFilter: {
        state: { equal: $state }
        lastUpdated: { greaterThan: $newerThan }
        isOverdue: { equal: $isOverdue }
      }
    ) {
      totalCount
    }
  }
`;
