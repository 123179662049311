import { gql } from '@apollo/client';

export const GET_APPOINTMENT_AGENT_SETTINGS = gql`
  query getAppointmentAgentSettings($id: String!, $first: Int, $after: String) {
    appointmentAgentSettings(id: $id) {
      __typename
      id
      name
      settings
      agentProfile
      optionalPrompts
      placeId
      textingCanWeTextSpeech
      textingOnYesSpeechCanWeText
      textingOnNoSpeechCanWeText
      textingMessageToSend
      textingHangupRecovery
      renderedTextingMessageToSend
      renderedTextingHangupRecovery
      minimumLeadTimeInMinutes
      maximumBookingHorizonInDays
      enableHangupRecovery
      activeIntegration {
        id
        pendingOpcodeSync
        integrationId
        xtimeSyncs(first: $first, after: $after) {
          edges {
            node {
              id
              createdOn
            }
            cursor
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
      latestXtimeSync {
        id
        endDate
      }
      place {
        id
        externalPlaceInfo {
          id
          linkGroups {
            groupLabel
            links {
              name
              tag
              url
            }
          }
        }
      }
    }
  }
`;
