import { $Values } from 'utility-types';
import isUndefined from 'lodash/isUndefined';
import capitalize from 'lodash/capitalize';

export const COLLECTION_NAMES = {
  ALL: 'all',
  ASSIGNED: 'assigned',
  UNASSIGNED: 'unassigned',
  MENTIONS: 'mentions',
  PLACE: 'place',
  SEARCH: 'search',
  STATUS: 'status',
  ADVISOR: 'advisor',
};

export const NON_SHARED_COLLECTION_NAMES = [
  COLLECTION_NAMES.ASSIGNED,
  COLLECTION_NAMES.MENTIONS,
];

export const NON_STATUS_BOARD_COLLECTION_NAMES = [
  COLLECTION_NAMES.ALL,
  COLLECTION_NAMES.ASSIGNED,
  COLLECTION_NAMES.UNASSIGNED,
  COLLECTION_NAMES.MENTIONS,
  COLLECTION_NAMES.PLACE,
  COLLECTION_NAMES.SEARCH,
  COLLECTION_NAMES.ADVISOR,
];

export type CollectionNames = $Values<typeof COLLECTION_NAMES>;

export const inboxIsCollection = (inboxId: string) =>
  !isUndefined(Object.values(COLLECTION_NAMES).find(name => name === inboxId));

export const convertInboxIdToTitle = (
  inboxId: string,
  allowPrimaryTodosInboxView = false,
) => {
  if (inboxIsCollection(inboxId)) {
    if (!allowPrimaryTodosInboxView) {
      return [COLLECTION_NAMES.ASSIGNED, COLLECTION_NAMES.ADVISOR].includes(
        inboxId,
      )
        ? 'You'
        : capitalize(inboxId);
    }
    switch (inboxId) {
      case COLLECTION_NAMES.ASSIGNED:
        return 'You';
      case COLLECTION_NAMES.ADVISOR:
        return 'To-dos';
      default:
        return capitalize(inboxId);
    }
  }
  return inboxId;
};
