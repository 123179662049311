import { gql } from '../../gql-tag';

export const PUBNUB_CONVERSATION_ENGAGEMENT_UPDATED = gql(`
  mutation PubNubConversationEngagementUpdated(
    $conversation: PubNubConversationModel!
  ) {
    PubNubConversationEngagementUpdated(
      conversation: $conversation
    ) @client
  }
`);
