import { gql } from '../../gql-tag';

export const AccountDetailsPlaceFragment = gql(`
  fragment AccountDetailsPlace on PlaceQL {
    id
    name
    newConversationsPerDay
    status
  }
`);

export const PlaceGroupAdminFragment = gql(`
  fragment AccountDetailsPlaceGroup on PlaceGroupQL {
    id
    name
    places(first: 1000) {
      edges {
        node {
          ... AccountDetailsPlace
        }
      }
    }
  }
`);

export const SubscriptionFragment = gql(`
  fragment AdminSubscriptionFields on SubscriptionQL {
    id
    available
    quantity
    sku {
      id
      skuType
      isActive
      name
    }
  }  
`);

export const GET_ACCOUNT_DETAILS_QUERY = gql(`
  query account($accountId: String!, $usersAfter: String) {
    account(id: $accountId) {
      id
      name
      status
      businessVertical
      useNewNumber
      placeGroups(first: 1000) {
        edges {
          node {
            ... AccountDetailsPlaceGroup
          }
        }
      }
      users(first: 200, after: $usersAfter) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            ...UserDetailFields
          }
        }
      }
      owners {
        id
        email
        firstName
        lastName
      }
      billingIdentity {
        id
        signupMode
        subscriptions {
          ... AdminSubscriptionFields
        }
      }
    }
  }
`);
