import { gql } from '../../gql-tag';

export const GET_AUTOPILOT_JOB = gql(`
  query getAutopilotJob($placeId: String!) {
    autopilotJob(placeId: $placeId) {
      id
      welcome
      inspection
      service
      qualityControl
      pickup
    }
  }
`);
