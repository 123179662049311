import { gql } from '@apollo/client';

export const UPDATE_PHONE_CALL = gql`
  mutation UpdatePhoneCall($input: UpdatePhoneCallInput!) {
    updatePhoneCall(input: $input) {
      phonecall {
        id
        isBeingRecorded
      }
    }
  }
`;
