import { gql } from '@apollo/client';

export const GET_ACTIVE_INTEGRATION = gql`
  query getActiveIntegration(
    $integrationId: String!
    $first: Int
    $after: String
  ) {
    activeIntegration(integrationId: $integrationId) {
      id
      placeId
      pendingOpcodeSync
      integration {
        id
        name
        configurationSchema
        description
        internalId
        categories
      }
      status
      configuration
      appointmentAgentSettings {
        id
        placeId
        name
        settings
      }
      xtimeSyncs(first: $first, after: $after) {
        edges {
          node {
            id
            createdOn
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`;
