import { gql } from '../../gql-tag';

export const CREATE_USER = gql(`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        id
        email
        jobRole
      }
      phoneline {
        id
        mdn
        name
        returnCallsMdn
        place {
          id
          name
        }
      }
    }
  }
`);
