import { PhoneCallFragment } from '../../fragments/gql/phoneCall.gql';
import {
  GetActiveInboundPhoneCallsQuery,
  GetActiveInboundPhoneCallsQueryVariables,
} from '../../graphql';
import {
  LOAD_CHAT_HISTORY_NUMBER_OF_MESSAGES,
  getChatHistoryQueryVariables,
} from '../../queries/LoadChatHistory';
import { GET_ACTIVE_INBOUND_PHONE_CALLS } from '../../queries/gql/getActiveInboundPhoneCalls.gql';
import { GET_CURRENT_USER } from '../../queries/gql/getCurrentUser.gql';
import { LOAD_CHAT_HISTORY } from '../../queries/gql/loadChatHistory.gql';
import type { ApolloContext } from '../../types';
import { safeRead } from '../../util';
import { convertTranscript } from './AddTranscriptToCache';

export const convertPhonecall = (
  call: PubNubInboundPhoneCallModel,
): PhoneCallFields => {
  return {
    __typename: 'PhoneCallQL',
    id: call.id,
    callDuration: call.call_duration || null,
    isActive: call.is_active,
    startTime: call.start_time,
    endtime: call.endtime,
    conversationId: call.conversation_id,
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ __typename: "TranscriptQL"; id: string;... Types of property '__typename' are incompatible.
    transcript: call.transcript ? convertTranscript(call.transcript) : null,
    hasReturnCallsRouting: call.has_return_calls_routing || false,
    returnCallsRoutingOutcome: call.return_calls_routing_outcome || null,
    returnCallsRoutedTo: call.return_calls_routed_to || null,
    returnCallsRoutedUser: call.return_calls_routed_user
      ? {
          __typename: 'UserQL',
          id: call.return_calls_routed_user.id,
          displayName: call.return_calls_routed_user.display_name,
        }
      : null,
    isBeingRecorded: call.is_being_recorded || false,
    participant: {
      __typename: 'ParticipantQL',
      id: call.participant.id,
      address: call.participant.address,
      displayName: call.participant.display_name,
    },
    redirectedFrom: call.redirected_from
      ? {
          __typename: 'ParticipantQL',
          id: call.redirected_from.id,
          address: call.redirected_from.address,
          displayName: call.participant.display_name,
        }
      : null,
    callRecording: call.call_recording
      ? {
          __typename: 'CallRecordingQL',
          id: call.call_recording.id,
          url: call.call_recording.url ?? null,
        }
      : null,
  };
};
/* eslint-disable react/destructuring-assignment */
export const PubNubInboundCallStatusUpdated = (
  obj: any,
  args: { call: PubNubInboundPhoneCallModel; conversation_id: string },
  { client }: ApolloContext,
) => {
  const conversationId = args.conversation_id;

  const currentUser = safeRead<getCurrentUser, getCurrentUserVariables>(
    client,
    { query: GET_CURRENT_USER },
  );

  const chatHistoryQueryVariables = getChatHistoryQueryVariables({
    conversationId,
    withScheduledMessages: true,
    numMessagesToLoad: LOAD_CHAT_HISTORY_NUMBER_OF_MESSAGES,
    includeSystemMessages:
      currentUser?.currentUser?.viewPreferences.systemMessageSettings
        ?.showSystemMessages,
  });

  const data = safeRead<loadChatHistory, loadChatHistoryVariables>(client, {
    query: LOAD_CHAT_HISTORY,
    variables: chatHistoryQueryVariables,
  });

  if (!data) {
    return null;
  }

  const phonecall = convertPhonecall(args.call);

  const activePhoneCallsWithoutMessages = [
    ...(data?.activePhoneCallsWithoutMessages ?? []).filter(
      (call: loadChatHistory$activePhoneCallsWithoutMessages) =>
        call.id !== phonecall.id,
    ),
    phonecall,
  ];

  client.writeQuery<loadChatHistory, loadChatHistoryVariables>({
    query: LOAD_CHAT_HISTORY,
    variables: chatHistoryQueryVariables,
    data: {
      ...data,
      activePhoneCallsWithoutMessages,
    },
  });

  const { returnCallsRoutedUser } = phonecall;
  if (
    returnCallsRoutedUser?.id &&
    returnCallsRoutedUser?.id === currentUser?.currentUser?.id
  ) {
    const inboundCallData = safeRead<
      GetActiveInboundPhoneCallsQuery,
      GetActiveInboundPhoneCallsQueryVariables
    >(client, {
      query: GET_ACTIVE_INBOUND_PHONE_CALLS,
      variables: { userId: returnCallsRoutedUser?.id },
    });

    const activeInboundPhoneCalls = [
      ...(inboundCallData?.activeInboundPhoneCalls ?? []).filter(
        call => call.id !== phonecall.id,
      ),
      phonecall,
    ] as GetActiveInboundPhoneCallsQuery['activeInboundPhoneCalls'];

    client.writeQuery<
      GetActiveInboundPhoneCallsQuery,
      GetActiveInboundPhoneCallsQueryVariables
    >({
      query: GET_ACTIVE_INBOUND_PHONE_CALLS,
      variables: { userId: returnCallsRoutedUser?.id },
      data: {
        ...inboundCallData,
        activeInboundPhoneCalls,
      },
    });
  }

  client.writeFragment({
    id: `PhoneCallQL:${phonecall.id}`,
    fragment: PhoneCallFragment,
    fragmentName: 'PhoneCallFields',
    data: phonecall,
  });

  return null;
};

/* eslint-enable */
