import get from 'lodash/get';
import { useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';
import { PlaceQl, UpdatePlaceMessageSettingsInput } from '../graphql';
import { GET_PLACE_BY_ID } from '../queries/gql/getPlaceById.gql';
import { gql } from '../gql-tag';

export const useGetPlaceById = (placeId: string) => {
  const { data, loading } = useQuery(GET_PLACE_BY_ID, {
    variables: { id: placeId },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const place: PlaceQl = get(data, 'place');

  return { place, loading };
};

const UPDATE_PLACE_MESSAGE_SETTINGS = gql(`
  mutation updatePlaceMessageSettings(
    $input: UpdatePlaceMessageSettingsInput!
  ) {
    updatePlaceMessageSettings(input: $input) {
      place {
        id
        escalationsEnabled
        escalationsThreshold
      }
    }
  }
`);

export const usePlaceMessageSettingsMutation = () => {
  const [updateMessageSettings] = useMutation(UPDATE_PLACE_MESSAGE_SETTINGS);

  return useCallback(
    (input: UpdatePlaceMessageSettingsInput) =>
      updateMessageSettings({
        variables: { input },
        refetchQueries: [
          {
            query: GET_PLACE_BY_ID,
            variables: { id: input.placeId },
          },
        ],
      }),
    [updateMessageSettings],
  );
};
