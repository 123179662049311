import { gql } from '../../gql-tag';

export const PhonelineCategorySettingsFragment = gql(`
  fragment PhonelineCategorySettingsFields on PhoneLineQL {
    id
    name
    category
    returnCallsMode
    returnCallsRoutingHours
    returnCallsForwardingMode
    returnCallsMdn
    canForwardReturnCalls
    nryForwardingMdn
    user {
      id
      fullName
    }
    smsChannel {
      id
    }
  }
`);
